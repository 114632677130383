<template>
    <v-container class="px-0">
        <Message/>
        <v-row v-if="itemsQuiz.length==0 && roleName=='Student'" justify="center" class="font-weight-bold">No Assessment Available!</v-row>
        <v-row v-else-if="itemsQuiz.length>0 && roleName=='Student'" justify="center" class="font-weight-bold">Available Assessments</v-row>
        <v-row justify="center" v-if="roleName=='Student'">
            <v-col cols="12" sm="6" md="4" v-for="item in itemsQuiz" :key="item.quizTopicId">
                <v-card color="grey lighten-3" shaped elevation="6">
                    <v-card-text class="pb-0">
                        <p class="title text--primary">{{item.quizTitle}}</p>
                    </v-card-text>
                    <v-list class="pa-0">
                        <v-list-item class="py-0">
                            <v-list-item-icon><v-icon>timer</v-icon></v-list-item-icon>
                            <v-list-item-title>Time</v-list-item-title>
                            <v-list-item-title>{{item.quizTime==0?'':item.quizTime+' minutes'}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="py-0">
                            <v-list-item-icon><v-icon>grade</v-icon></v-list-item-icon>
                            <v-list-item-title>Marks</v-list-item-title>
                            <v-list-item-title>{{item.quizTotalMarks==0?'':item.quizTotalMarks}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item class="py-0">
                            <v-list-item-icon><v-icon>help</v-icon></v-list-item-icon>
                            <v-list-item-title>Questions</v-list-item-title>
                            <v-list-item-title>{{item.questionsCount}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <form v-bind:action="stripePostUrl" method="POST" v-if="item.quizPrice>0 && item.paymentCount==0 && chkPaymentAllowed==true">
                            <input type="hidden" name="quizTopicId" v-bind:value="item.quizTopicId">
                            <input type="hidden" name="adminId" v-bind:value="loggedUserId">
                            <v-btn type="submit" class="text-capitalize white--text" color="grey darken-2" @click="saveQuizTopicId(item)">Pay {{getCurrency}}{{item.quizPrice}}</v-btn>
                        </form>
                        <v-btn v-else class="white--text" color="grey darken-2" @click="startQuiz(item)">Start</v-btn>
                    </v-card-actions>                   
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="roleName=='Admin'">
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{name:'Users'}" class="grey lighten-1">
                    <v-card-title>
                        <v-icon x-large left>mdi-account-multiple</v-icon>       
                    </v-card-title>
                    <v-card-text>Candidates <span class="black--text">{{totalStudents}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{name:'QuizTopics'}" class="grey lighten-2">
                    <v-card-title>
                        <v-icon x-large left>emoji_objects</v-icon>       
                    </v-card-title>
                    <v-card-text>Assessments <span class="black--text">{{totalQuizes}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{name:'QuizTopics'}" class="grey lighten-3">
                    <v-card-title>
                        <v-icon x-large left>emoji_objects</v-icon>       
                    </v-card-title>
                    <v-card-text>Live Assessments <span class="black--text">{{liveQuizes}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{name:'Quizes'}" class="grey lighten-4">
                    <v-card-title>
                        <v-icon x-large left>help_center</v-icon>       
                    </v-card-title>
                    <v-card-text>Questions <span class="black--text">{{totalQuestions}}</span></v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="roleName=='SuperAdmin'">
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card :to="{name:'Users'}" class="grey lighten-1">
                    <v-card-title>
                        <v-icon x-large left>mdi-account-multiple</v-icon>       
                    </v-card-title>
                    <v-card-text>Admin <span class="black--text">{{totalStudents}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card class="grey lighten-2">
                    <v-card-title>
                        <v-icon x-large left>emoji_objects</v-icon>       
                    </v-card-title>
                    <v-card-text>Assessments <span class="black--text">{{totalQuizes}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card class="grey lighten-3">
                    <v-card-title>
                        <v-icon x-large left>emoji_objects</v-icon>       
                    </v-card-title>
                    <v-card-text>Live Assessments <span class="black--text">{{liveQuizes}}</span></v-card-text>
                </v-card>
            </v-col>
            <v-col class="pl-2" cols="12" sm="6" md="3">
                <v-card class="grey lighten-4">
                    <v-card-title>
                        <v-icon x-large left>help_center</v-icon>       
                    </v-card-title>
                    <v-card-text>Questions <span class="black--text">{{totalQuestions}}</span></v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <Analytics class="pa-0" v-if="roleName=='Admin' || roleName=='SuperAdmin'"/>
    </v-container>
</template>

<script>
import config from '../../config'
import Analytics from '../../components/common/Analytics'
import Message from '../../components/common/Message'

export default {
    name:'Dashboard',
    components:{
        Analytics,
        Message,
    },
    data(){
        return{         
            itemsQuiz:[],  
            userInfo:{},
            totalStudents:'',
            totalQuizes:'',
            liveQuizes:'',
            totalQuestions:'',
            userId:null,
            isPaymentAllowed:false,                   
        }
    },
    methods:{
        initializeStudent(){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('dashboard/fetchLiveQuizes',this.userInfo.email)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.itemsQuiz=response.data
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        initializeAdmin(id){
            this.$store.dispatch('dashboard/applyLoading')
            this.$store.dispatch('dashboard/fetchSummary',id)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response.status==200){
                    this.totalStudents=response.data.totalStudents
                    this.totalQuizes=response.data.totalQuizes
                    this.liveQuizes=response.data.liveQuizes
                    this.totalQuestions=response.data.totalQuestions
                }
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        startQuiz(item){
            this.$store.dispatch('dashboard/applyLoading')
            const objResponseInitial={
                userId:parseInt(localStorage.getItem('loggedUserId')),
                email:this.userInfo.email,
                quizTopicId:item.quizTopicId,
                quizTitle:item.quizTitle,
                quizMark:item.quizTotalMarks,
                quizPassMarks:item.quizPassMarks,
                quizTime:item.quizTime,
                addedBy:parseInt(localStorage.getItem('loggedUserId'))
            }
            this.$store.dispatch('dashboard/createInitialResponse',objResponseInitial)
            .then((response)=>{
                this.$store.dispatch('dashboard/cancelLoading')
                if(response.status==200){
                    this.$store.dispatch('dashboard/startQuiz',item)
                    this.$store.dispatch('dashboard/saveQuizLiveTime',item.quizTime)
                    this.$router.push({name:'StartQuiz'})
                }else if(response.status==202){
                    this.$root.$emit('message_from_parent_long',response.data.responseMsg)
                }
            })
        },
        saveQuizTopicId(item){
            localStorage.setItem('quizTopicId', item.quizTopicId)
        },
        roleActions(){
            if(this.userInfo.roleName=='Student'){
                this.$store.dispatch('dashboard/applyLoading')
                this.$store.dispatch('settings/fetchSingleSettings',this.userInfo.addedBy)
                .then((response)=>{
                    this.$store.dispatch('dashboard/cancelLoading')
                    if(response.status==200){
                        if(response.data.currency!='' && response.data.currencySymbol!='' && response.data.stripeSecretKey!=''){
                            this.isPaymentAllowed=true
                        }else{
                            this.isPaymentAllowed=false
                        }
                        this.initializeStudent()
                    }
                })
                .catch((err)=>{
                    console.log(err)
                })
            }else if(this.userInfo.roleName=='Admin'){
                this.initializeAdmin(this.userInfo.userId)
                this.$store.dispatch('settings/fetchSingleSettings',this.userInfo.userId)
            }else if(this.userInfo.roleName=='SuperAdmin'){
                this.initializeAdmin(this.userInfo.userId)
            }
        },
        makePayment(){
            var url = new URL(window.location.href);
            var sessionId = url.searchParams.get('session_id');
            if(sessionId){
                var uniqueId=sessionId.replace(/{|}/g,'')
                fetch(config.hostname+'/checkout-session-quiz-pay?sessionId='+uniqueId+'&adminId='+this.userInfo.addedBy)
                .then(response => response.json())
                    .then(data=>{
                            if(data.id==uniqueId && data.paymentStatus=='paid'){                      
                                const obj={
                                    quizTopicId:parseInt(localStorage.getItem('quizTopicId')),
                                    email:this.userInfo.email,
                                    amount:data.amountTotal/100,
                                    currency:this.$store.getters['settings/currency'],
                                    sessionId:uniqueId,
                                    addedBy:this.userInfo.userId,
                                    adminId:this.userInfo.addedBy
                                }
                                this.$store.dispatch('quiz/createPayment',obj)
                                .then(response=>{
                                    if(response.status==200){
                                        location.replace(window.location.origin+'/dashboard')                                       
                                    }else if(response.status==202){
                                        this.$root.$emit('message_from_parent',response.data.responseMsg)
                                    }
                                })
                                .catch(err=>{
                                    console.log(err)
                                })
                            }
                        }
                    )
                .catch(function (err) {
                    console.log('Error when fetching Checkout session', err);
                });
            }
        },
        makeRenewal(){
            var url = new URL(window.location.href);
            var renewId = url.searchParams.get('renew_id')
            if(renewId){
                var stripeUniqueId=renewId.replace(/{|}/g,'')
                fetch(config.hostname+'/checkout-session?sessionId='+stripeUniqueId)
                .then(response => response.json())
                .then(data=>{
                        if(data.id==stripeUniqueId && data.paymentStatus=='paid'){
                            const objNewPlan={
                                addedBy:this.userInfo.userId,
                                transactionEmail:data.customerDetails.email,
                                userEmail:this.userInfo.email,
                                stripeSessionId:stripeUniqueId,
                                billingPlanId:localStorage.getItem('billingPlanId'),
                                paymentMode:'Online',
                                transactionDetail:stripeUniqueId
                            }
                            this.$store.dispatch('user/updateBillingPlan',objNewPlan)
                            .then((response)=>{
                                if(response.status==200){
                                    this.$router.push({name:'Landing'})
                                    this.$root.$emit('message_from_parent_long','Billing plan renewal is successful ! Sign in again.')                     
                                }
                            })
                            .catch((err)=>{
                                console.log(err)
                            })                         
                        }
                    }
                )
                .catch(function (err) {
                    console.log('Error when fetching Checkout session', err);
                });
            }
        }
    },
    computed:{  
        loggedUserId(){
            return this.userInfo.addedBy
        },
        roleName(){
            return this.userInfo.roleName
        },
        getCurrency(){
            return this.$store.getters['settings/currencySymbol']
        },
        stripePostUrl(){
            return config.hostname+'/pay-for-quiz'
        },
        chkPaymentAllowed(){
            return this.isPaymentAllowed
        },    
    },
    created(){
        this.userInfo=this.$store.getters['dashboard/userInfo']     
        this.$store.dispatch('dashboard/changeVisibility')
        this.$store.dispatch('dashboard/saveQuestionSerial',0)
        this.$store.dispatch('dashboard/saveQuizFlag',false)
        this.roleActions()
        if(this.userInfo.roleName=='Student'){
            this.$store.dispatch('dashboard/saveQuestionSerial',0)
            this.$store.dispatch('dashboard/saveSerialToShow',1)
            this.makePayment()
        }else if(this.userInfo.roleName=='Admin'){
            this.makeRenewal()
        }
    }
}
</script>