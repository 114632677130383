<template>
    <v-app>
        <nav>
            <v-navigation-drawer
                v-model="drawer"
                app
                dark
                temporary
            >
                <v-list-item class="pb-2">
                    <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        {{this.siteName}}
                    </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list dense>
                    <v-list-item
                        v-for="([icon, text, link], i) in items"
                        :key="i"
                        link
                        @click="$vuetify.goTo(link)"
                    >
                        <v-list-item-icon class="justify-center">
                            <v-icon>{{ icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="subtitile-1">
                            {{text}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item @click="switchSignIn">
                        <v-list-item-icon class="justify-center">
                            <v-icon>login</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="subtitile-1">
                            Sign In
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list> 
            </v-navigation-drawer>
            <v-app-bar app elevation="14">  
                <v-toolbar-title><v-img :src="this.logoPath" max-height="50" max-width="150" contain /></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon v-if="isXs==true" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <div v-else>
                    <v-btn text @click="$vuetify.goTo('#home')"><span class="text-capitalize">Home</span></v-btn>
                    <v-btn text @click="$vuetify.goTo('#pricing')"><span class="text-capitalize">Pricing</span></v-btn>
                    <v-btn text @click="$vuetify.goTo('#features')"><span class="text-capitalize">Features</span></v-btn>
                    <v-btn text @click="$vuetify.goTo('#contact')"><span class="text-capitalize">Contact</span></v-btn>
                </div>
                <v-spacer v-if="isXs==false"></v-spacer>
                <v-btn v-if="isXs==false" outlined @click="switchSignIn" class="text-capitalize">Sign In</v-btn>                              
            </v-app-bar>
        </nav>
        <v-main>
            <Message/>
            <section id="home">            
                <v-row justify="center" class="homeMargin">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" md="6" align-self="center">
                                <h1 class="header">Taking an assessment is always difficult!</h1>
                                <div class="bodyText">We believe that scientific assessment activities help create a fair and merit-based society. This is why we develop culturally appropriate assessments and tests for you.Measure your candidates skill on a way that is secure and robust.Then what are you waiting for?</div>
                                <v-btn outlined @click="$vuetify.goTo('#pricing')" class="text-capitalize">Get Registered</v-btn>
                            </v-col>
                            <v-col cols="12" md="6" align-self="start">
                                <v-img :src="this.homeImg"></v-img>
                            </v-col>
                        </v-row>
                        
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="10" md="7">
                        <h1 class="header text-center">Online Assessment Platform for educational institutions, employers, training providers and professional bodies</h1>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="10" md="10">
                        <p class="bodyText text-center">The most advanced assessment platform on internet</p>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mt-16 mb-16">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <v-card shaped elevation="16" min-height="185px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">Educators</v-row></v-card-title>
                                    <v-card-text>We help educators to measure & develop employability skills to ensure success in education, careers, and everyday life.</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-card shaped elevation="16" min-height="185px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">Employers</v-row></v-card-title>
                                    <v-card-text>Our online testing platform helps organisations determine whether their new and existing staff have the modern skills.</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-card shaped elevation="16" min-height="185px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">Training Providers</v-row></v-card-title>
                                    <v-card-text>Our online tests and assessments make it easier for training providers to recognize achievement.</v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-card shaped elevation="16" min-height="185px">
                                    <v-card-title class="grey darken-3 white--text"><v-row justify="center">Professional Bodies</v-row></v-card-title>
                                    <v-card-text>We enable associations to deliver reliable online assessments and successful certification programmes.</v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>

            <section id="pricing" class="mt-16 mb-16">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" sm="6" md="4" v-for="item in itemsPlan" :key="item.billingPlanId">
                                <v-card hover shaped outlined class="cardBorder">
                                    <v-card-title class="pt-12"><v-row justify="center">{{item.title}}</v-row></v-card-title>
                                    <v-card-subtitle class="text-center">{{item.interval}}</v-card-subtitle>
                                    <v-card-subtitle class="text-center"><v-avatar color="grey darken-1" size="70"><span class="lime--text text-h5">${{item.price}}</span></v-avatar></v-card-subtitle>
                                    <v-card-text class="py-8">
                                        <v-row justify="center" class="black--text">{{item.assessmentCount}} assessments</v-row>
                                        <v-divider></v-divider>
                                        <v-row justify="center" class="black--text">{{item.questionPerAssessmentCount}} questions on each assessment</v-row>
                                        <v-divider></v-divider>
                                        <v-row justify="center" class="black--text">take {{item.responsePerAssessmentCount}} responses for each assessment</v-row>
                                        <v-divider></v-divider>
                                        <v-row justify="center" class="black--text">{{item.additionalText}}</v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row justify="center" v-if="item.price>0">
                                            <form v-bind:action="stripePostUrl" method="POST">
                                                <input type="hidden" name="billingId" v-bind:value="item.billingPlanId">
                                                <v-btn type="submit" @click="saveBillingPlan(item)" class="text-capitalize" outlined>Subscribe</v-btn>
                                            </form>
                                        </v-row>   
                                        <v-row justify="center" v-else><v-btn @click="saveBillingPlan(item)" class="text-capitalize" outlined>Subscribe</v-btn></v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>


            <section id="features" class="mt-16 mb-16">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row>
                            <v-col cols="12" md="6" align-self="center" order="1">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">Experience a new horizon</h3></v-card-title>
                                    <v-card-subtitle>
                                        Experience the advance platform for assessing your candidates
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" order="2">
                                <v-img max-width="500" max-height="500" :src="this.feature1" contain></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" order="2" order-md="1">
                                <v-img max-width="500" max-height="500" :src="this.feature2"></v-img>
                            </v-col>
                            <v-col cols="12" md="6" align-self="center" order="1" order-md="2">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">Flexible question settings</h3></v-card-title>
                                    <v-card-subtitle>
                                        There are various types of questions for you to include in the assessment
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" align-self="center" order="1">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">justify your candidates</h3></v-card-title>
                                    <v-card-subtitle>
                                        Check the various answers given by candidates for assessment
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6" order="2">
                                <v-img max-width="500" max-height="500" :src="this.feature3"></v-img>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" order="2" order-md="1">
                                <v-img max-width="500" max-height="500" :src="this.feature4"></v-img>
                            </v-col>
                            <v-col cols="12" md="6" align-self="center" order="1" order-md="2">
                                <v-card flat>
                                    <v-card-title><h3 class="headerFeature">Analyse your candidates</h3></v-card-title>
                                    <v-card-subtitle>
                                        Get indepth analysis of your candidates through our assessment system
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>

            <section id="register" class="mt-16 mb-16">
                <v-card class="white--text text-center" dark>
                   <!--  <v-card-text class="pt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</v-card-text> -->
                    <v-card-text class="pt-10">Register to avail all services from AGASS Online Assess</v-card-text>
                    <v-row class="pb-10" justify="center"><v-card-actions><v-btn @click="$vuetify.goTo('#pricing')" class="text-capitalize" outlined>Register now</v-btn></v-card-actions></v-row>
                </v-card>
            </section>

            <section id="contact" class="mt-16 mb-16">
                <v-row justify="center">
                    <v-col cols="10">
                        <v-row>
                             <v-col cols="12" sm="5">
                                <h1>Contact-us</h1>
                                <!-- <h4 class="font-weight-light mt-3">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste
                                    explicabo commodi quisquam asperiores dolore ad enim provident
                                    veniam perferendis voluptate, perspiciatis.
                                </h4> -->
                                <h4 class="font-weight-light mt-3">
                                    <!-- Lorem ipsum dolor sit amet consectetur adipisicing. -->
                                    Contact AGASS for any queries. 
                                </h4>
                                <h4 class="font-weight-light mt-3">
                                    Number : +91 9814065254
                                </h4>
                                <h4 class="font-weight-light">
                                    Email : assess@agass.online
                                </h4>
                            </v-col>
                            <v-col cols="12" sm="7">
                                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                                    <v-text-field
                                        v-model="name"
                                        :rules="nameRules"
                                        label="Name"
                                        required
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        label="E-mail"
                                        required
                                    ></v-text-field>

                                    <v-textarea
                                        v-model="textArea"
                                        :rules="textAreaRules"
                                        label="Message"
                                        required
                                    />

                                    <v-btn
                                        :disabled="!valid"
                                        color="primary"
                                        :dark="valid"
                                        rounded
                                        block
                                        class="mt-3"
                                        @click="submit"
                                    >
                                    Submit
                                    </v-btn>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </section>
        </v-main>
        <v-footer
            dark
            padless
        >
            <v-card
            flat
            tile
            width="100%"
            class="text-center"
            >
            <v-card-text>
                <v-btn
                v-for="icon in icons"
                :key="icon.img"
                :href="icon.route"
                class="mx-4 white--text"
                icon
                >
                <v-icon size="24px">
                    {{ icon.img }}
                </v-icon>
                </v-btn>
            </v-card-text>

            <v-card-text class="pt-0">
                Part of AGASS network
                <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.  -->
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text class="white--text">
                Copyright © AGASS. All Rights Reserved
                <!-- {{ new Date().getFullYear() }} — <strong>{{this.siteName}}</strong> -->
            </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import config from '../../config'
import Message from '../../components/common/Message'

export default{
    name: "Landing",
    components: { Message },
    data() {
        return {
            drawer: null,
            isXs: false,
            itemsPlan:[],
            items: [
                ["mdi-home-outline", "Home", "#home"],
                ["monetization_on", "Pricing", "#pricing"],
                ["featured_play_list", "Features", "#features"],
                ["contact_support", "Contact", "#contact"],
            ],
            /* icons: [
                "mdi-facebook",
                "mdi-twitter",
                "mdi-linkedin",
                "mdi-instagram",
            ], */
            icons: [
                { img:'mdi-facebook',text: 'facebook', route: 'https://facebook.com/agassorg'},
                { img:'mdi-youtube',text: 'youtube', route: 'https://youtube.com/channel/UCx5aTH0cXUjJJSF786FDxFg'},
                { img:'mdi-linkedin',text: 'linkedin', route: 'https://linkedin.com/company/agass'},
                { img:'mdi-instagram',text: 'instagram', route: 'https://instagram.com/agassorg'},
            ],
            logoPath: "",
            homeImg: window.location.origin + "/upload/home.webp",
            feature1: window.location.origin + "/upload/feature1.webp",
            feature2: window.location.origin + "/upload/feature2.webp",
            feature3: window.location.origin + "/upload/feature3.webp",
            feature4: window.location.origin + "/upload/feature4.webp",
            siteName: "",
            defaultLogoUrl: window.location.origin + "/upload/logo-assess-hour.png",
            valid: true,
            name: "",
            nameRules: [
                (v) => !!v || "Name can not be empty",
                (v) => (v && v.length >= 6) || "Length must be greater than or equal to 6 caracteres",
            ],
            email: "",
            emailRules: [
                (v) => !!v || "Email can not be empty",
                (v) => /.+@.+\..+/.test(v) || "Wrong Email!",
            ],
            textArea: "",
            textAreaRules: [
                (v) => !!v || "Message can not be empty",
                (v) => (v && v.length >= 10) || "Length must be greater than or equal to 10 caracteres",
            ],
            lazy: false,
        };
    },
    methods: {
        onResize() {
            this.isXs = window.innerWidth < 850;
        },
        switchSignIn() {
            this.$router.push({ name: "SignIn" });
        },
        initializePlan(){
            this.$store.dispatch('settings/fetchBillingPlans')
            .then((response)=>{
                this.itemsPlan=response.data
            })
            .catch((err)=>{
                console.log(err)
            })
        },
        submit() {
            if (this.$refs.form.validate()) {
                const obj = {
                    name: this.name,
                    email: this.email,
                    message: this.textArea
                };
                this.$store.dispatch('settings/createContacts', obj)
                    .then(response => {
                    if (response.status == 200) {
                        this.$root.$emit("message_from_parent", response.data.responseMsg);
                    }
                    else if (response.status == 202) {
                        this.$root.$emit("message_from_parent_long", response.data.responseMsg);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
            }
        },
        saveBillingPlan(item){
            localStorage.setItem('billingPlanId', item.billingPlanId)
            localStorage.setItem('billingPlanPrice', item.price)
            if(item.price==0){
                this.$router.push({name:'SignIn'}) 
            }
        },
        updateClientUrl(){
            const obj={
                displayName:window.location.origin
            }
            this.$store.dispatch('settings/updateClientUrl',obj)
        }
    },
    watch: {
        isXs(value) {
            if (!value) {
                if (this.drawer) {
                    this.drawer = false;
                }
            }
        },
    },
    computed:{
        stripePostUrl(){
            return config.hostname+'/create-checkout-session-plan'
        }   
    },
    mounted() {
        this.onResize();
        window.addEventListener("resize", this.onResize, { passive: true });
    },
    created() {
        this.$store.dispatch('dashboard/signOut')
        this.logoPath = this.$store.getters["settings/logoPath"] == "" ? this.defaultLogoUrl : config.hostname + this.$store.getters["settings/logoPath"];
        this.siteName = this.$store.getters["settings/siteTitle"] == "" ? "Assess" : this.$store.getters["settings/siteTitle"];
        this.initializePlan()
        this.updateClientUrl()
    },
}
</script>
<style scoped>
    .header{
        font-size: 45px;
        margin-bottom: 20px;
    }
    .headerFeature{
        white-space:pre-wrap;
        word-break:break-word;
    }
    .homeMargin{
        margin-bottom: 150px;
    }
    .bodyText{
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 35px;
    }
    .cardBorder{
        border-color: black;
        border-radius: 1px;
    }
</style>